import { graphql, useStaticQuery } from "gatsby"
import React, { Fragment, useEffect, useState } from "react"
import { DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap"
import { CountriesGrid, Link } from ".."
import useCountriesInLanguage from "../../hooks/useCountriesInLanguage"
import ContraceptiveMethodImg from "../../images/contraceptive-method.svg"
import ContraceptiveQuizImg from "../../images/contraceptive-quiz.svg"
import ClickAwayListener from "react-click-away-listener"
//import parse from 'html-react-parser';

const dropdownImageData = [
  {
    image: ContraceptiveQuizImg,
    alt: "Online Contraceptive quiz",
    title: "Contraceptive Quiz",
  },
  {
    image: ContraceptiveMethodImg,
    alt: "Best Contraceptive Method",
    title: "Compare Methods",
  },
]

const HeaderMenu = ({ lang }) => {
  const [langMenus, setLangMenus] = useState([])
  const countriesInLanguage = useCountriesInLanguage(lang)
  const [showToolsDropdown, setShowToolsDropdown] = useState(false)
  const [showPregnantDropdown, setShowPregnantDropdown] = useState(false)
  const [showFaqDropdown, setShowFaqDropdown] = useState(false)

  const { header } = useStaticQuery(graphql`
    query {
      header: allWpFmmMenu(
        sort: { fields: fmm_menu___order }
        filter: { fmm_menu: { position: { eq: "header" } } }
      ) {
        edges {
          node {
            fmm_menu {
              dropdown
              order
              parentOrderId
              position
            }
            title
            fmmCore {
              languageCode
              frontendSlug
            }
          }
        }
      }
    }
  `)

  useEffect(() => {
    const menusList = header?.edges?.filter(
      item => item?.node?.fmmCore?.languageCode === lang
    )

    setLangMenus(menusList)
  }, [lang, header])
  const filterTools = header?.edges?.filter(
    item => item?.node?.fmm_menu?.parentOrderId === 1
  )
  const toolsOnLang = filterTools.filter(
    item => item?.node?.fmmCore?.languageCode === lang
  )
  const filterPregnant = header?.edges?.filter(
    item => item?.node?.fmm_menu?.parentOrderId === 4
  )
  const pregnatOnLang = filterPregnant.filter(
    item => item?.node?.fmmCore?.languageCode === lang
  )
  const filterFAQ = header?.edges?.filter(
    item => item?.node?.fmm_menu?.parentOrderId === 6
  )
  const faqOnLang = filterFAQ.filter(
    item => item?.node?.fmmCore?.languageCode === lang
  )
  const parentMenu = langMenus.filter(
    item => item?.node?.fmm_menu?.parentOrderId === null
  )

  const toggleToolsDropdown = () =>
    setShowToolsDropdown(prevState => !prevState)
  const togglePregnantDropdown = () =>
    setShowPregnantDropdown(prevState => !prevState)
  const toggleFaqDropdown = () => setShowFaqDropdown(prevState => !prevState)

  return (
    <>
      {parentMenu[0]?.node?.fmmCore?.frontendSlug && (
        <div>
          <Link
            to={parentMenu[0]?.node?.fmmCore?.frontendSlug}
            lang={lang}
            activeClassName="active"
          >
            <span
              className="menu-parent"
              dangerouslySetInnerHTML={{ __html: parentMenu[0]?.node?.title }}
            />
          </Link>
        </div>
      )}

      {toolsOnLang.length >= 1 && (
        <UncontrolledDropdown
          className="dropdown"
          onClick={toggleToolsDropdown}
        >
          <DropdownToggle className="button--link">
            <span
              className="menu-parent"
              dangerouslySetInnerHTML={{ __html: parentMenu[1]?.node?.title }}
            />
          </DropdownToggle>

          {showToolsDropdown && (
            <div className="drop-down--container">
              <ul className="drop-down__items">
                {toolsOnLang.map((item, index) => {
                  return (
                    // <ClickAwayListener
                    //   onClickAway={event => {
                    //     setShowToolsDropdown(false)
                    //   }}
                    // >
                    <Fragment key={index}>
                      <li className="drop-down__item">
                        {dropdownImageData[index] ? (
                          <img
                            src={dropdownImageData[index].image}
                            alt={dropdownImageData[index].alt}
                            width={52}
                            height={48}
                          />
                        ) : null}

                        <Link
                          lang={lang}
                          to={`${item?.node?.fmmCore?.frontendSlug}`}
                        >
                          <span>{item.node?.title}</span>
                        </Link>
                      </li>
                    </Fragment>
                    // </ClickAwayListener>
                  )
                })}
              </ul>
            </div>
          )}
        </UncontrolledDropdown>
      )}

      {parentMenu[3]?.node?.fmmCore?.frontendSlug && (
        <Link
          to={parentMenu[3]?.node?.fmmCore?.frontendSlug}
          activeClassName="active"
          target="_blank"
        >
          <span
            className="menu-parent"
            dangerouslySetInnerHTML={{ __html: parentMenu[3]?.node?.title }}
          />
        </Link>
      )}

      {parentMenu[2]?.node?.fmmCore?.frontendSlug && (
        <Link
          to={parentMenu[2]?.node?.fmmCore?.frontendSlug}
          lang={lang}
          activeClassName="active"
        >
          <span
            className="menu-parent"
            dangerouslySetInnerHTML={{ __html: parentMenu[2]?.node?.title }}
          />
        </Link>
      )}

      {pregnatOnLang.length >= 1 && (
        <UncontrolledDropdown
          className="dropdown"
          onClick={togglePregnantDropdown}
        >
          <DropdownToggle className="button--link">
            <span
              className="menu-parent"
              dangerouslySetInnerHTML={{ __html: parentMenu[4]?.node?.title }}
            />
          </DropdownToggle>

          {showPregnantDropdown && (
            <div className="drop-down--container">
              <ul className="drop-down__items">
                {pregnatOnLang.map((item, index) => {
                  return (
                    <Fragment key={index}>
                      <li className="drop-down__item">
                        <Link
                          lang={lang}
                          to={`${item?.node?.fmmCore?.frontendSlug}`}
                        >
                          <span>{item.node?.title}</span>
                        </Link>
                      </li>
                    </Fragment>
                  )
                })}
              </ul>
            </div>
          )}
        </UncontrolledDropdown>
      )}

      {parentMenu[5]?.node?.fmmCore?.frontendSlug && (
        <Link
          to={parentMenu[5]?.node?.fmmCore?.frontendSlug}
          lang={lang}
          activeClassName="active"
        >
          <span
            className="menu-parent"
            dangerouslySetInnerHTML={{ __html: parentMenu[5]?.node?.title }}
          />
        </Link>
      )}

      {faqOnLang.length >= 1 && (
        <UncontrolledDropdown className="dropdown" onClick={toggleFaqDropdown}>
          <DropdownToggle className="button--link">
            <span
              className="menu-parent"
              dangerouslySetInnerHTML={{ __html: parentMenu[6]?.node?.title }}
            />
          </DropdownToggle>

          {showFaqDropdown && (
            <div className="drop-down--container">
              <ul className="drop-down__items">
                {faqOnLang.map((item, index) => {
                  return (
                    <Fragment key={index}>
                      <li className="drop-down__item">
                        <Link
                          lang={lang}
                          to={`${item?.node?.fmmCore?.frontendSlug}`}
                        >
                          <span>{item.node?.title}</span>
                        </Link>
                      </li>
                    </Fragment>
                  )
                })}
              </ul>
            </div>
          )}

          {/* <DropdownMenu className="dropdown-style faq-dropdown">
            <ul className={`faq-list ${lang}`}>
              {faqOnLang.map((item, index) => (
                <Fragment key={index}>
                  <li>
                    <Link
                      lang={lang}
                      to={`${item?.node?.fmmCore?.frontendSlug}`}
                    >
                      <span>{item.node?.title}</span>
                    </Link>
                  </li>
                </Fragment>
              ))}
            </ul>
          </DropdownMenu> */}
        </UncontrolledDropdown>
      )}
    </>
  )
}

export default HeaderMenu
