import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import { Helmet } from "react-helmet"
import { useLocation } from '@reach/router'

const HrefLangs = ({ languages, slug = '/', lang }) => {
  const { pathname } = useLocation();
  
  const {
     site: {
      siteMetadata: { siteUrl, default_lang },
    },
    } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            siteUrl
            default_lang
          }
        }
      }
    `
  );

  //const urlPath = `${siteUrl}${pathname}`;

  const hasEnglishSite = languages?.find(
    language => language.node.fmmCore.languageCode === 'en' 
    
  );

  const DontEnglishSite = languages?.find(
    language => language.node.fmmCore.languageCode !== 'en' 
  );
  
  const excludeOtherLanguages = languages?.filter( language => language.node.fmmCore.languageCode !== 'pcm' && language.node.fmmCore.languageCode !== 'yo')
  
  //const pageLang = languages?.map((lang, index) => {if (index === 0){return lang.node.fmmCore.languageCode}})
  const pageCurrentLang =  lang === 'en' ? '' : `/${lang}`
  const xDefaultLang = hasEnglishSite ? '' : pageCurrentLang;
  const isHomepage = slug === '/' && '';
  const otherPages = slug && slug[0] !== '/' ? `/${slug}` : slug;
  const xDefaultSlug = isHomepage || otherPages;
  const xDefaultHref = `${siteUrl}${xDefaultLang}${xDefaultSlug}`;

/* console.log(`slug + slug[0] ${slug} + ${slug[0]} `)
  console.log(`pageCurrentLang ${pageCurrentLang}`)
  console.log(`xDefaultLang ${xDefaultLang}`)
  console.log(`languages ${languages.value}`)
  console.log(`hasEnglishSite ${hasEnglishSite}`)
  console.log(`isHomepage ${isHomepage}`)
  console.log(`otherPages ${otherPages}`)
  console.log(`xDefaultSlug ${xDefaultSlug}`)
  console.log(`xDefaultHref ${xDefaultHref}`)
  console.log(NoPcm)*/

 
  return (
    <Helmet>
      <link rel="canonical" href={`https://findmymethod.org${pathname}`} />
      <link rel="alternate" hreflang="x-default" href={xDefaultHref} />

      {excludeOtherLanguages?.reverse()?.map((lang, index) => (
        <link
          rel="alternate"
          key={index}
          hreflang={lang?.node?.fmmCore?.languageCode}
          href={`${siteUrl}${
            lang?.node?.fmmCore?.languageCode === default_lang 
              ? ''
              : `/${lang?.node?.fmmCore?.languageCode}`
          }${
            slug === '/' && lang?.node?.fmmCore?.languageCode === default_lang
              ? ''
              : slug[0]  !== '/'
              ? `/${slug}`
              : slug
          }`}
        />
      ))}
    </Helmet>
  )
}

export default HrefLangs
