import React from "react"
import { Container, Row } from "reactstrap"
import { Link, CookiePopup, SocialIcons } from ".."
import FMMLogoWhiteMobile from "../../images/fmm-logo-white-mobile.svg"
import FMMLogoWhite from "../../images/fmm-logo-white.svg"
import "./Footer.scss"
import FooterMenu from "./FooterMenu"
import BrandText from "../BrandText/BrandText"
import ChatBot from "./ChatBot"

const Footer = ({ lang, copyrightMessage }) => {
  return (
    <>
      <footer className="footer">
        <Container>
          <Row className="d-flex align-items-end px-4 mb-md-4 mb-0">
            <div className="footer__top">
              <div className="col-12">
                <Link to="/" lang={lang}>
                  <picture>
                    <source
                      media="(max-width: 767px)"
                      srcSet={FMMLogoWhiteMobile}
                      width=""
                    />
                    <img
                      src={FMMLogoWhite}
                      alt="Find My Method"
                      height="60"
                      width="192"
                      loading="lazy"
                    />
                  </picture>
                </Link>
              </div>
            </div>

            <section className="col-12 col-md-6 text-md-left text-center mb-3 mb-md-1">
              <BrandText />
            </section>
          </Row>

          <Row>
            <section className="col-12">
              <FooterMenu lang={lang} />
            </section>
          </Row>

          <Row className="d-flex flex-column flex-md-row align-items-center justify-content-md-between justify-content-center px-4">
            <section className="order-1">
              <p className="footer__copyright">
                © {new Date().getFullYear()} {copyrightMessage}
              </p>
            </section>

            <section className="d-flex align-items-center order-0 order-md-1">
              <span className="pr-4 d-none d-md-block">Contact</span>
              <SocialIcons mobileMenuOpen={false} />
            </section>
          </Row>
        </Container>
      </footer>

      <CookiePopup lang={lang} />

      <ChatBot />
    </>
  )
}

export default Footer
