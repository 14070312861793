import { useEffect } from "react"

const ChatBot = () => {
  useEffect(() => {
    setTimeout(() => {
      const script = document.createElement("script")
      script.src = "https://platform.botscrew.net/widget/script-chatbot.js"
      script.type = "text/javascript"
      script.setAttribute(
        "data-server-url",
        "https://platform.botscrew.net/api"
      )
      script.setAttribute("data-icon-position", "bottom right 5% 15px")
      script.setAttribute("id", "chatbot-initials-script")
      script.setAttribute("data-bot-id", "a3100449-8913-4944-8e95-d848f3ff144f")
      document.body.appendChild(script)
    }, 6000)
  }, [])

  return null
}

export default ChatBot
