import React from "react"

const BrandText = () => (
  <a
    href="https://womenfirstdigital.org/"
    target="_"
    className="footer__brandtext"
  >
    Powered by Women First Digital
  </a>
)

export default BrandText
