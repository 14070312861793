import React from "react"

const SocialIcons = ({ inverted, mobileMenuOpen }) => {
  return (
    <nav className={`social-icons ${inverted ? "inverted" : ""}`}>
      <a
        href="https://www.facebook.com/findmymethod/"
        target="_blank"
        rel="noreferrer"
        aria-label="Facebook"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="30"
          height="30"
          viewBox="0 0 30 30"
          fill="none"
          aria-hidden="true"
          focusable="false"
          data-prefix="fab"
          data-icon="facebook-f"
          className="svg-inline--fa fa-facebook-f fa-w-10"
        >
          <path
            fill={`${mobileMenuOpen ? "#262262" : "#ffffff"}`}
            d="M15.1061 0.000375067C12.1394 -0.0206003 9.2331 0.838628 6.75476 2.4694C4.27641 4.10018 2.33731 6.42925 1.18267 9.1621C0.028026 11.8949 -0.290294 14.9088 0.267962 17.8226C0.826219 20.7363 2.23598 23.4191 4.31896 25.5316C6.40194 27.6442 9.0646 29.0916 11.9702 29.6908C14.8758 30.2901 17.8939 30.0142 20.6427 28.8982C23.3915 27.7822 25.7477 25.8761 27.4132 23.4209C29.0787 20.9658 29.9788 18.0719 29.9996 15.1053C30.015 13.1351 29.6418 11.1813 28.9013 9.35554C28.1609 7.52978 27.0678 5.86791 25.6845 4.465C24.3012 3.06209 22.6549 1.94566 20.8397 1.17957C19.0246 0.413491 17.0762 0.0127836 15.1061 0.000375067ZM18.4907 15.0212H16.2906L16.2482 23.1447H13.202L13.2445 15.0212H11.2132V12.228H13.2445V10.5777C13.2445 8.29275 13.8791 6.89693 16.5867 6.93858H18.8293V9.73098H17.4296C16.3715 9.73098 16.3291 10.1119 16.3291 10.8314V12.228H18.8254L18.4907 15.0212Z"
          />
        </svg>
      </a>
      <a
        href="https://twitter.com/findmymethod"
        target="_blank"
        rel="noreferrer"
        aria-label="Twitter"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="30"
          height="30"
          viewBox="0 0 30 30"
          fill="none"
          aria-hidden="true"
          focusable="false"
          data-prefix="fab"
          data-icon="twitter"
          className="svg-inline--fa fa-twitter fa-w-16"
          role="img"
        >
          <path
            d="M14.9999 0C6.71687 0 -0.00012207 6.717 -0.00012207 15C-0.00012207 23.283 6.71687 30 14.9999 30C23.2829 30 29.9999 23.283 29.9999 15C29.9999 6.717 23.2829 0 14.9999 0ZM21.8487 11.6954C21.8553 11.843 21.8586 11.9913 21.8586 12.1404C21.8586 16.6898 18.3956 21.936 12.0626 21.9363H12.0629H12.0626C10.1183 21.9363 8.30898 21.3663 6.78531 20.3897C7.0547 20.4215 7.3289 20.4373 7.60654 20.4373C9.2197 20.4373 10.7042 19.8871 11.8827 18.9635C10.3755 18.9356 9.1048 17.9402 8.66626 16.5722C8.87614 16.6125 9.09198 16.6344 9.31331 16.6344C9.62756 16.6344 9.93198 16.5921 10.2213 16.5131C8.64589 16.1977 7.45914 14.8055 7.45914 13.1383C7.45914 13.1227 7.45914 13.1087 7.45959 13.0943C7.92354 13.3523 8.45409 13.5075 9.01897 13.5249C8.09451 12.908 7.48683 11.8536 7.48683 10.659C7.48683 10.0282 7.65735 9.43726 7.95306 8.92845C9.65091 11.0117 12.1883 12.3818 15.0498 12.5258C14.9907 12.2736 14.9603 12.0108 14.9603 11.7407C14.9603 9.84009 16.5023 8.29811 18.4036 8.29811C19.394 8.29811 20.2884 8.71674 20.9167 9.38599C21.7011 9.23126 22.4376 8.9447 23.103 8.55034C22.8455 9.35394 22.2998 10.0282 21.5889 10.4546C22.2854 10.3713 22.9492 10.1866 23.566 9.91241C23.1053 10.603 22.5209 11.2095 21.8487 11.6954Z"
            fill={`${mobileMenuOpen ? "#262262" : "#ffffff"}`}
          />
        </svg>
      </a>
      <a
        href="https://instagram.com/findmymethod"
        target="_blank"
        rel="noreferrer"
        aria-label="Instagram"
      >
        <svg
          width="30"
          height="30"
          aria-hidden="true"
          focusable="false"
          data-prefix="fab"
          data-icon="instagram"
          className="svg-inline--fa fa-instagram fa-w-14"
          role="img"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 30 30"
        >
          <path
            d="M19.1924 8.42876H10.8079C10.489 8.42671 10.1729 8.48801 9.87786 8.6091C9.58285 8.73018 9.31483 8.90865 9.08934 9.13414C8.86385 9.35963 8.68539 9.62765 8.5643 9.92266C8.44321 10.2177 8.38191 10.5338 8.38396 10.8527V19.2372C8.38191 19.5561 8.44321 19.8722 8.5643 20.1673C8.68539 20.4623 8.86385 20.7303 9.08934 20.9558C9.31483 21.1813 9.58285 21.3597 9.87786 21.4808C10.1729 21.6019 10.489 21.6632 10.8079 21.6612H19.1924C19.5113 21.6632 19.8274 21.6019 20.1225 21.4808C20.4175 21.3597 20.6855 21.1813 20.911 20.9558C21.1365 20.7303 21.3149 20.4623 21.436 20.1673C21.5571 19.8722 21.6184 19.5561 21.6164 19.2372V10.8527C21.6184 10.5338 21.5571 10.2177 21.436 9.92266C21.3149 9.62765 21.1365 9.35963 20.911 9.13414C20.6855 8.90865 20.4175 8.73018 20.1225 8.6091C19.8274 8.48801 19.5113 8.42671 19.1924 8.42876ZM14.9801 19.3961C14.1156 19.3961 13.2704 19.1397 12.5516 18.6594C11.8328 18.1791 11.2725 17.4964 10.9416 16.6977C10.6108 15.899 10.5242 15.0201 10.6929 14.1721C10.8616 13.3242 11.2779 12.5453 11.8892 11.934C12.5005 11.3227 13.2794 10.9064 14.1273 10.7377C14.9753 10.569 15.8542 10.6556 16.6529 10.9864C17.4516 11.3173 18.1343 11.8775 18.6146 12.5964C19.0949 13.3152 19.3513 14.1604 19.3513 15.0249C19.353 15.5994 19.2412 16.1686 19.0221 16.6997C18.803 17.2309 18.4811 17.7134 18.0749 18.1197C17.6686 18.5259 17.1861 18.8478 16.6549 19.0669C16.1238 19.286 15.5546 19.3978 14.9801 19.3961ZM19.5102 11.5676C19.3058 11.5676 19.106 11.507 18.936 11.3934C18.7661 11.2799 18.6336 11.1185 18.5554 10.9296C18.4772 10.7408 18.4567 10.533 18.4966 10.3325C18.5365 10.1321 18.6349 9.94795 18.7794 9.80342C18.924 9.65889 19.1081 9.56047 19.3086 9.5206C19.509 9.48072 19.7168 9.50119 19.9057 9.57941C20.0945 9.65762 20.2559 9.79008 20.3694 9.96002C20.483 10.13 20.5436 10.3298 20.5436 10.5342C20.5446 10.6702 20.5186 10.805 20.467 10.9308C20.4155 11.0567 20.3394 11.171 20.2432 11.2672C20.147 11.3634 20.0327 11.4394 19.9069 11.491C19.781 11.5426 19.6462 11.5686 19.5102 11.5676Z"
            fill={`${mobileMenuOpen ? "#262262" : "#ffffff"}`}
          />
          <path
            fill={`${mobileMenuOpen ? "#262262" : "#ffffff"}`}
            d="M14.9786 12.5186C14.4834 12.5186 13.9995 12.6654 13.5878 12.9405C13.1761 13.2155 12.8552 13.6065 12.6658 14.0639C12.4763 14.5214 12.4267 15.0247 12.5233 15.5103C12.6199 15.9959 12.8583 16.4419 13.2084 16.792C13.5585 17.1421 14.0046 17.3806 14.4902 17.4772C14.9758 17.5737 15.4791 17.5242 15.9366 17.3347C16.394 17.1452 16.7849 16.8244 17.06 16.4127C17.3351 16.001 17.4819 15.517 17.4819 15.0219C17.4832 14.6928 17.4194 14.3667 17.2941 14.0624C17.1687 13.758 16.9844 13.4816 16.7517 13.2488C16.5189 13.0161 16.2424 12.8318 15.9381 12.7064C15.6338 12.5811 15.3077 12.5172 14.9786 12.5186Z"
          />
          <path
            fill={`${mobileMenuOpen ? "#262262" : "#ffffff"}`}
            d="M14.9793 7.18806e-06C12.0126 0.00412837 9.11371 0.88789 6.64926 2.53954C4.18482 4.19118 2.26548 6.53653 1.13398 9.27899C0.00247101 12.0215 -0.290391 15.0379 0.292427 17.9468C0.875244 20.8557 2.30756 23.5264 4.40826 25.6213C6.50896 27.7162 9.18368 29.1411 12.0942 29.7158C15.0047 30.2906 18.0203 29.9893 20.7596 28.8502C23.4989 27.7111 25.8389 25.7853 27.4837 23.3162C29.1285 20.8472 30.0042 17.9459 30.0001 14.9792C29.993 11.0014 28.4068 7.18927 25.5902 4.38049C22.7736 1.5717 18.957 -0.00388337 14.9793 7.18806e-06ZM23.9916 19.5062C23.9896 20.7176 23.5074 21.8789 22.6508 22.7355C21.7942 23.5922 20.6329 24.0743 19.4215 24.0764H10.5364C9.3249 24.0743 8.16364 23.5922 7.30701 22.7355C6.45037 21.8789 5.96822 20.7176 5.96618 19.5062V10.5787C5.96822 9.36721 6.45037 8.20595 7.30701 7.34932C8.16364 6.49268 9.3249 6.01053 10.5364 6.00849H19.4639C20.6753 6.01053 21.8366 6.49268 22.6932 7.34932C23.5499 8.20595 24.032 9.36721 24.034 10.5787V19.5062H23.9916Z"
          />
        </svg>
      </a>
      <a
        href="https://www.youtube.com/channel/UCRmHe0dZ16aAN9mKxF54cNQ?view_as=subscriber"
        target="_blank"
        rel="noreferrer"
        aria-label="YouTube"
      >
        <svg
          width="30"
          height="30"
          aria-hidden="true"
          focusable="false"
          data-prefix="fab"
          data-icon="youtube"
          className="svg-inline--fa fa-youtube fa-w-18"
          role="img"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 30 30"
        >
          <path
            fill={`${mobileMenuOpen ? "#262262" : "#ffffff"}`}
            d="M13.1316 17.8099L18.0109 14.9997L13.1316 12.1895V17.8099Z"
          />
          <path
            fill={`${mobileMenuOpen ? "#262262" : "#ffffff"}`}
            d="M14.9999 0C6.71687 0 -0.00012207 6.717 -0.00012207 15C-0.00012207 23.283 6.71687 30 14.9999 30C23.2829 30 29.9999 23.283 29.9999 15C29.9999 6.717 23.2829 0 14.9999 0ZM24.3726 15.0153C24.3726 15.0153 24.3726 18.0574 23.9867 19.5243C23.7704 20.3272 23.1373 20.9603 22.3344 21.1764C20.8675 21.5625 14.9999 21.5625 14.9999 21.5625C14.9999 21.5625 9.1476 21.5625 7.66536 21.161C6.86244 20.945 6.22935 20.3117 6.01306 19.5087C5.62694 18.0574 5.62694 15 5.62694 15C5.62694 15 5.62694 11.9582 6.01306 10.4913C6.22913 9.68834 6.87778 9.03969 7.66536 8.82362C9.13226 8.4375 14.9999 8.4375 14.9999 8.4375C14.9999 8.4375 20.8675 8.4375 22.3344 8.83896C23.1373 9.05502 23.7704 9.68834 23.9867 10.4913C24.3882 11.9582 24.3726 15.0153 24.3726 15.0153Z"
          />
        </svg>
      </a>
      <a
        href="https://www.tiktok.com/@findmymethod?lang=en"
        target="_blank"
        rel="noreferrer"
        aria-label="Tiktok"
      >
        <svg
          width="30"
          height="30"
          aria-hidden="true"
          focusable="false"
          data-prefix="fab"
          data-icon="tiktok"
          className="svg-inline--fa fa-tiktok fa-w-14"
          role="img"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 30 30"
        >
          <path
            fill={`${mobileMenuOpen ? "#262262" : "#ffffff"}`}
            d="M14.9999 0C6.71689 0 -0.00012207 6.71701 -0.00012207 15C-0.00012207 23.283 6.71689 30 14.9999 30C23.2829 30 29.9999 23.283 29.9999 15C29.9999 6.71701 23.2829 0 14.9999 0ZM22.5251 11.4769V13.508C21.5674 13.5083 20.6368 13.3206 19.7592 12.9504C19.1949 12.7121 18.6691 12.4052 18.1883 12.0342L18.2027 18.2858C18.1966 19.6935 17.6397 21.0161 16.6317 22.0124C15.8114 22.8233 14.7719 23.339 13.6453 23.5059C13.3806 23.5451 13.1112 23.5652 12.8387 23.5652C11.6327 23.5652 10.4877 23.1745 9.55107 22.4537C9.37482 22.318 9.20624 22.1709 9.04576 22.0124C7.95333 20.9327 7.39001 19.4694 7.48488 17.9299C7.55724 16.7581 8.0264 15.6405 8.80851 14.7646C9.84328 13.6055 11.2909 12.9622 12.8387 12.9622C13.1112 12.9622 13.3806 12.9826 13.6453 13.0218V13.7728V15.8617C13.3943 15.7789 13.1262 15.7334 12.8471 15.7334C11.433 15.7334 10.2889 16.8871 10.3101 18.3023C10.3235 19.2077 10.8181 19.9992 11.5476 20.4355C11.8904 20.6406 12.2848 20.7676 12.7057 20.7907C13.0355 20.8088 13.3521 20.7629 13.6453 20.6661C14.6556 20.3323 15.3845 19.3833 15.3845 18.2641L15.3878 14.0777V6.43477H18.1849C18.1876 6.71191 18.2157 6.98232 18.2679 7.24436C18.4791 8.30496 19.0769 9.22512 19.9089 9.85242C20.6344 10.3996 21.5378 10.724 22.517 10.724C22.5176 10.724 22.5257 10.724 22.525 10.7234V11.4769H22.5251Z"
          />
        </svg>
      </a>
    </nav>
  )
}

export default SocialIcons
